<template>
  <div id="vessel_record_by_flag">
    <apexchart ref="apex-chart" style="max-width: 1580px; min-width: 1186.8px" @click="selectedBar" type="pie" height="auto" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import appLoading from "@/components/elements/AppLoading.vue";
import {ExcelService} from "@/services/ExcelService";
import {VesselService} from "@/services/VesselService";

export default {
  name: "VesselRecordPieChart",
  props:{
    series : {
      type : Array,
      default: null
    },
    labels : {
      type : Array,
      default : null
    },
    legendPosition : {
      type : String,
      default : 'left'
    },
    categories:{
      type: Array,
      default: null
    },
    years:{
      type: Array,
      default: null
    },
    categoryObject:{
      type:Array|Object,
      default:null
    },
    office:{
      type:String|Number,
      default:null,
    },
  },
  mixins:[PaginationMixin],
  components:{appLoading},
  data() {
    return {
      year:null,
      loading:false,
      currentEvent:null,
      chartOptions: {
        plotOptions: {
          pie: {
            customScale: 0.7,
          }
        },
        chart: {
          width: 300,
          type: 'pie',
          responsive: false
        },
        labels: this.labels,
        tooltip: {
          enabled: true,
          style : {
            color : '#fff'
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize : '20px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: new Array(this.series.length).fill('#fff'),
          },
          background: {
            enabled: true,
            foreColor: '#000',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          },
          formatter: function(seriesName, opts) {
            return opts.w.globals.series[opts.seriesIndex];
          }
        },
        legend: {
          position: this.legendPosition,
          formatter: function(seriesName, opts) {
            return seriesName + ": " + `<strong>` + opts.w.globals.series[opts.seriesIndex] + `</strong>`;
          }
        },
      },
    }
  },
  methods:{
    ...mapActions(['getAccidentIncidentByEvent']),
    async selectedBar(evt){
      const innerText = evt.target.innerHTML;
      const loweredInnerText = innerText.toString().substring(0, 1).toLowerCase()
      if(this.categoryLetters.includes(loweredInnerText)){
        this.currentEvent=this.categoryObject.find((evt2) => {
          const loweredEvt = evt2.event.substring(0, 1).toLowerCase()

          return loweredEvt == loweredInnerText;
        });
        $('#exampleModal').modal('show');
        this.loading=true;
        this.year=null;
        await this.setPage(null,null,this.office);
        this.loading=false;
      }
    },
    async setPage(page = null,year=null,office=null){
      const params = {
        eventOne:this.currentEvent?.eventOneId,
        page:page ?? 1,
        year:year ?? null,
        office: office ?? null
      }
      this.loading=true;
      await this.getAccidentIncidentByEvent(params);
      this.loading=false;
    },
    async generateCsv(){
      swal.fire({
        'title': 'GENERATING CSV FILE',
        allowEscapeKey:false,
        allowEnterKey:false,
        allowOutsideClick:false,
      })
      swal.showLoading()
      await ExcelService.csv('ACCIDENT_INCIDENT_EVENT',{eventOne:this.currentEvent?.eventOneId ?? null,year:this.year ?? null,office:this.office})
      swal.close();
    },
    async showReport (reportId) {
      const route= this.$router.resolve(
        {
          name: 'AccidentIncidentReport',
          params: {
            id: reportId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async getGraphImage() {
      const chart = this.$refs["apex-chart"].chart;
      const { imgURI } = await chart.dataURI();
      const byteString = atob(imgURI.split(',')[1]);
      const mimeString = imgURI.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: mimeString });
      const formData = new FormData();
      formData.append("chart_image", blob, "chart.png");
      formData.append("report_type", this.$route.params.report_type);


      swal.fire({
        'title': `DOWNLOADING VESSEL SUMMARY GRAPH BY ${this.$route.params.report_type.toUpperCase()}<br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      const printResponse = await VesselService.printSummaryOfManagingVesselGraph(formData);
      swal.close()
      let url = window.URL.createObjectURL(new Blob([printResponse.data],{type:'application/pdf'}))
      let filename=`graph_by_flag_${Date.now()}.pdf`;
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      // console.log(imageURI)
    }
  },
  computed:{
    ...mapGetters(['accidentIncidentPerEvent','pagination']),
    categoryLetters(){
      return this.categories.map( evt=>{
        return evt.substring(0, 1).toLowerCase()
      })
    }
  },

  watch:{
    'year'(val){
      this.setPage(null,val,this.office)
    }
  }
}
</script>

<style scoped>
table tr td{
  font-size: 14px !important;
}

.apexcharts-legend {
  top: 50% !important;
}




</style>
