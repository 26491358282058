<template>
<div class="container-fluid px-4">
  <template v-if="loading">
    <app-loading></app-loading>
  </template>
  <template v-else>
    <div class="row">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-11">
            <h2 class="h2 e-text-red">Summary of Managing Vessels</h2>
          </div>
          <div class="col-1">
            <button id="downloadManagingVesselSummary" class="ml-2 mr-2 e-btn e-btn-blue-gray force-white-all"
                    @click="downloadManagingVesselSummary"
            >
              <font-awesome-icon class="mr-1" icon="download"/>
              Download
            </button>
          </div>
        </div>

        <div class="row col-12 p-0 m-0">
          <table class="table text-center table-sm small e-tbl">
            <thead class="bg-white">
            <tr>
              <th>ISM Management Company</th>

              <th class="text-uppercase" v-for="data in vesselManagement.vesselCountOnEachFleet" :colspan="data?.management_offices?.reduce((total, office) => total + office?.fleets.length, 0)">{{ data.name }}</th>
            </tr>

            <tr>
              <th>Management Branch Office</th>
              <template v-for="data in vesselManagement.vesselCountOnEachFleet">
                <th class="text-uppercase align-middle" v-for="office in data.management_offices" :colspan="office.fleets.length">
                  {{ office.name }}
                </th>
              </template>
            </tr>

            <tr>
              <th>Fleet</th>
              <template v-for="data in vesselManagement.vesselCountOnEachFleet">
                <template v-for="office in data.management_offices">
                  <th class="text-uppercase align-middle" v-for="fleet in office.fleets">
                    {{ fleet.name }}
                  </th>
                </template>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th class="custom">Number Of vessel</th>
              <template v-for="data in vesselManagement.vesselCountOnEachFleet">
                <template v-for="office in data.management_offices">
                  <td class="text-uppercase align-middle" v-for="fleet in office.fleets">
                    <a href="#" @click="getVesselListByParam(data.id, office.id, fleet.id)"> {{ fleet.vessel_count }} </a>
                  </td>
                </template>
              </template>
            </tr>
            <tr>
              <th class="custom">Sub Total By branch office</th>
              <template v-for="data in vesselManagement.vesselCountOnEachFleet">
                <template v-for="office in data.management_offices">
                  <td :colspan="office.fleets.length" class="align-middle">
                    <a href="#" @click="getVesselListByParam(data.id, office.id)">
                      {{ office.fleets.reduce((total, fleet) => total + fleet.vessel_count, 0)}}
                    </a>
                  </td>
                </template>
              </template>
            </tr>

            <tr>
              <th class="custom">Sub Total By DOC</th>
              <template v-for="data in vesselManagement.vesselCountOnEachFleet">
                  <td :colspan="data?.management_offices?.reduce((total, office) => total + office?.fleets.length, 0)" class="align-middle">
                    <a href="#" @click="getVesselListByParam(data.id)">
                      {{ data?.management_offices?.reduce((management_total, office) => management_total + office?.fleets.reduce((office_total, fleet) => office_total + fleet.vessel_count, 0), 0) }}
                    </a>
                  </td>
              </template>
            </tr>

            <tr>
              <th class="custom">Total</th>
                <td class="align-middle" :colspan="vesselManagement.vesselCountOnEachFleet.reduce((company_total, company) => company_total + company.management_offices.reduce((office_total, office) => office_total + office.fleets.length, 0), 0)">
                  <a href="#" @click="getVesselListByParam()">
                    {{ vesselManagement.vesselCountOnEachFleet.reduce(
                    (total, company) => total + company.management_offices?.reduce(
                      (office_total, office) => office_total + office.fleets.reduce(
                        (fleet_total, fleet) => fleet_total + fleet.vessel_count,
                        0),
                      0),
                    0) }}
                  </a>
                </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: $route.name, params : {
              report_type : 'flag'
            }}">
                <span style="height: 100%">BY FLAG</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: $route.name, params : {
              report_type : 'age'
            }}">
                <span>BY AGE</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
      <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
    </div>
    <div class="row" v-if="$route.params.report_type === 'flag' && vesselRecordByFlagSeries.length > 0">
      <div class="col-12">
        <div class="card shadow-sm rounded mb-3 mt-3">
          <div class="card-body">
            <div>
              <div class="row align-items-center">
                <div class="col-10">
                  <h5>BY FLAG</h5>
                </div>
                <div class="col-2">
                  <button id="downloadManagingVesselSummary" class="ml-2 float-right e-btn e-btn-blue-gray force-white-all"
                          @click="$refs['vessel-pie-flag'].getGraphImage()"
                  >
                    <font-awesome-icon class="mr-1" icon="download"/>
                    Download Graph
                  </button>
                </div>
              </div>
              <VesselRecordPieChart
                :key="$route.params.report_type + '_' + vesselRecordByFlagSeries.length"
                :series="vesselRecordByFlagSeries"
                :labels="vesselRecordByFlagLabels.map(str => str.toUpperCase())"
                ref="vessel-pie-flag"
              />
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="$route.params.report_type === 'age' && vesselRecordByAgeSeries.length > 0">
      <div class="col-12">
        <div class="card shadow-sm rounded mb-3 mt-3">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-10">
                <h5>BY AGE</h5>
              </div>
              <div class="col-2">
                <button id="downloadManagingVesselSummary" class="ml-2 float-right e-btn e-btn-blue-gray force-white-all"
                        @click="$refs['vessel-pie-age'].getGraphImage()"
                >
                  <font-awesome-icon class="mr-1" icon="download"/>
                  Download Graph
                </button>
              </div>
            </div>
            <VesselRecordPieChart
              :key="$route.params.report_type + '_' + vesselRecordByAgeSeries.length"
              :series="vesselRecordByAgeSeries"
              :labels="vesselRecordByAgeLabels.map(str => str.toUpperCase())"
              ref="vessel-pie-age"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  <div class="modal fade" id="vesselSummaryList" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xxl modal-dialog-centered" style="max-width: 80vw;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="small e-tbl">
            <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
              <tr>
                <th class="align-middle text-center" style="width: 1em" scope="col">#</th>
                <th class="align-middle text-center" scope="col">VESSEL NAME</th>
                <th class="align-middle text-center" scope="col">FLAG</th>
                <th class="align-middle text-center" scope="col">MANAGEMENT COMPANY</th>
                <th class="align-middle text-center" scope="col">MANAGEMENT OFFICE</th>
                <th class="align-middle text-center" scope="col">FLEET</th>
                <th class="align-middle text-center" scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
            <template v-if="modalLoading">
              <tr>
                <td colspan="100%">
                  <app-loading></app-loading>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="(data,index) in vesselSummaryList ">
                <td class="align-middle text-center text-uppercase">{{ index + 1 }}</td>
                <td class="align-middle text-center text-uppercase">{{ data?.name }}</td>
                <td class="align-middle text-center text-uppercase">{{ data?.flag?.name }}</td>
                <td class="align-middle text-center text-uppercase">{{ data?.management_company?.name }}</td>
                <td class="align-middle text-center text-uppercase">{{ data?.management_office?.name }}</td>
                <td class="align-middle text-center text-uppercase">{{ data?.fleet?.name }}</td>
                <td class="align-middle text-center text-uppercase">
                  <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" @click="showVessel(data.id)">
                    <font-awesome-icon icon="eye"  />
                    View
                  </button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {permissionMixin} from "@/mixins/permissionMixins";
import {mapActions, mapGetters} from "vuex";
import VesselRecordPieChart from "@/components/dashboard/number-of-management/VesselRecordPieChart.vue";
import AppLoading from "@/components/elements/AppLoading.vue";
import {VesselService} from "@/services/VesselService";


export default {
  name: 'NumberOfManagement',
  mixins:[permissionMixin],
  data(){
    return {
      loading : true,
      modalLoading : false
    }
  },
  components: {
    AppLoading,
    VesselRecordPieChart
  },
  methods:{
    ...mapActions([
      'getDashboardVesselManagement',
      'getMngmtOfficeKeys',
      'getVesselSummaryList',
    ]),
    async getVesselListByParam(management_company_id, management_office_id, fleet_id) {
      let param = {}
      if (management_company_id) {
        param.management_company_id = management_company_id;
      }
      if (management_office_id) {
        param.management_office_id = management_office_id;
      }
      if (fleet_id) {
        param.fleet_id = fleet_id;
      }
      $('#vesselSummaryList').modal('show');
      this.modalLoading = true;
      await this.getVesselSummaryList({
        management_company_id,
        management_office_id,
        fleet_id,
      });
      this.modalLoading = false;
    },
    async showVessel(vesselId) {
      const route= this.$router.resolve(
        {
          name: 'VesselParticular',
          params: {
            id: vesselId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async downloadManagingVesselSummary() {
      swal.fire({
        'title': `DOWNLOADING MANAGING VESSEL SUMMARY<br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      const downloadResponse = await VesselService.downloadManagingVesselSummary();
      swal.close()
      let url = window.URL.createObjectURL(new Blob([downloadResponse.data],{type:'application/pdf'}))
      let filename=`summary_of_managing_vessels_${Date.now()}.pdf`;
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }
  },
  async created() {
    await this.getMngmtOfficeKeys();
    await this.getDashboardVesselManagement();

    this.loading = false;
  },
  computed:{
    ...mapGetters([
      'mngmntOfficeKeys',
      'vesselManagement',
      'vesselSummaryList',
    ]),
    vesselRecordByFlagSeries() {
      return this.vesselManagement?.vesselRecordByFlag?.map(record => record.vessel_count);
    },
    vesselRecordByFlagLabels() {
      return this.vesselManagement?.vesselRecordByFlag?.map(record => record.name);
    },
    vesselRecordByAgeSeries() {
      return this.vesselManagement?.vesselRecordByAge?.map(record => record.count);
    },
    vesselRecordByAgeLabels() {
      return this.vesselManagement?.vesselRecordByAge?.map(record => record.name);
    },
  },
}
</script>

<style scoped >
#ModelNav {
  padding: 0;
  li > a {
    padding: .5rem 2rem;
    color: #435464;
    height: 100%;
    text-align: center;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
  .nav-link span {
    font-size: 14px;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  font-weight: bold;

  * {
    color: #d3313c;
  }
}

.e-tbl thead tr th, .e-tbl tbody tr th.custom, .e-tbl tbody tr td{
  border: 1px solid #b1b1b1;
}
</style>

